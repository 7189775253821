<template>
  <div class="details">
    <div class="title">{{data.cname}}</div>
    <div class="line" v-if="data.cname">
      <div class="line-title">【 手术名称 】</div>
      <div class="line-text" v-html="data.cname"></div>
    </div>
    <div class="line" v-if="data.alias">
      <div class="line-title">【 别&nbsp&nbsp&nbsp&nbsp&nbsp名 】</div>
      <div class="line-text" v-html="data.alias"></div>
    </div>
    <div class="line" v-if="data.ename">
      <div class="line-title">【 英&nbsp文&nbsp名 】</div>
      <div class="line-text" v-html="data.ename"></div>
    </div>
    <div class="line" v-if="data.category_path">
      <div class="line-title">【 分&nbsp&nbsp&nbsp&nbsp&nbsp类 】</div>
      <div class="line-text" v-html="data.category_path"></div>
    </div>
    <div class="line" v-if="data.icd">
      <div class="line-title">【 ICD编码 】</div>
      <div class="line-text" v-html="data.icd"></div>
    </div>
    <div class="line" v-if="data.anatomy">
      <div class="line-title">【 相关解剖 】</div>
      <div class="line-text" v-html="data.anatomy"></div>
    </div>
    <div class="column" v-if="data.summarization">
      <div class="line-title">【 概&nbsp&nbsp&nbsp&nbsp&nbsp述 】</div>
      <div class="line-text" v-html="data.summarization"></div>
    </div>
    <div class="column" v-if="data.indications">
      <div class="line-title">【 适&nbsp应&nbsp症 】</div>
      <div class="line-text" v-html="data.indications"></div>
    </div>
    <div class="column" v-if="data.contraindication">
      <div class="line-title">【 禁&nbsp忌&nbsp症 】</div>
      <div class="line-text" v-html="data.contraindication"></div>
    </div>
    <div class="column" v-if="data.prepare">
      <div class="line-title">【 术前准备 】</div>
      <div class="line-text" v-html="data.prepare"></div>
    </div>
    <div class="column" v-if="data.anesthesia">
      <div class="line-title">【 麻醉和体位 】</div>
      <div class="line-text" v-html="data.anesthesia"></div>
    </div>
    <div class="column" v-if="data.procedure">
      <div class="line-title">【 手术步骤 】</div>
      <div class="line-text" v-html="data.procedure"></div>
    </div>
    <div class="column" v-if="data.attention">
      <div class="line-title">【 术中注意要点 】</div>
      <div class="line-text" v-html="data.attention"></div>
    </div>
    <div class="column" v-if="data.afterprepare">
      <div class="line-title">【 术后处理 】</div>
      <div class="line-text" v-html="data.afterprepare"></div>
    </div>
    <div class="column" v-if="data.commend">
      <div class="line-title">【 述&nbsp&nbsp&nbsp&nbsp&nbsp评 】</div>
      <div class="line-text" v-html="data.commend"></div>
    </div>
    <div class="line" v-if="data.download">
      <div class="line-title">【 操&nbsp&nbsp&nbsp&nbsp&nbsp作 】</div>
      <div class="line-text">
        <a href="" @click.prevent="onDownload(data, '3')">下 载</a>
      </div>
    </div>
    <div class="foot-details" v-if="data.disease">
      <div class="line-title">【 相关疾病 】</div>
      <div class="line-text">
        <ul v-html="data.disease"></ul>
      </div>
    </div>
    <div class="foot-details" v-if="data.movie">
      <div class="line-title">【 相关视频 】</div>
      <div class="line-text">
        <ul v-html="data.movie"></ul>
      </div>
    </div>
    <div class="foot-details" v-if="data.picture">
      <div class="line-title">【 相关图谱 】</div>
      <div class="line-text">
        <ul v-html="data.picture"></ul>
      </div>
    </div>
    <div class="foot-details" v-if="data.evidence">
      <div class="line-title">【 相关循证 】</div>
      <div class="line-text">
        <ul v-html="data.evidence"></ul>
      </div>
    </div>

  </div>
</template>

<script>
  import mixins from '../../mixins'
  export default {
    name: "operationDisease",
    mixins: [
      mixins
    ],
    data(){
      return {
        type: '3',
      }
    },
    created() {
      if (this.$route.query.id || this.$route.query.ID){
        this.getData({ id: this.$route.query.id || this.$route.query.ID, type: this.type })
      }
    }
  }
</script>
